import React, { Component } from "react";

import AtlasIcon from "components/Icon/atlasIcon";
import CustomFormInput from "components/Input/formInput";

const passwordChecks = password => [
  {
    label: "Lowercase characters",
    condition: /[a-z]/.test(password),
  },
  {
    label: "Uppercase characters",
    condition: /[A-Z]/.test(password),
  },
  {
    label: "Digit number",
    condition: /\d/.test(password),
  },
  {
    label: "Special character (@#$%^&+!=)",
    condition: /[@#$%^&+!=]/.test(password),
  },
  {
    label: "12 characters minimum",
    condition: password.length >= 12,
  }
]

class ResetPassword extends Component {
  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      return this.props.onClickSubmitPassword();
    }
  };

  onChangeNewPassword = (val) => {
    Promise.all([this.props.onChangeHOC(val, "password")]).then(() => {
      if(!val) {
        const tmpPasswordError = val 
          ? "" 
          : "Please fill in the password field."
        return this.props.onChangeHOC(tmpPasswordError, "passwordError");
      }
      
      const tmpBothPasswordsConfirmationError = val === this.props.confirmedPassword
        ? ""
        : "Password and confirm password must be the same."
      const passwordValidationError = val.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@\#$%^&+=!]).{12,}$/)
        ? ""
        : "Password must contain at least 12 characters, including uppercase, lowercase, number, and special character (@#$%^&+!=)."
      
      this.props.onChangeHOC(tmpBothPasswordsConfirmationError, "passwordConfirmationError");
      this.props.onChangeHOC(passwordValidationError, "passwordError");
    });
  };

  onChangeConfirmNewPassword = (val) => Promise.all([
    this.props.onChangeHOC(val, "confirmedPassword")
  ]).then( () => {
    if(!val) {
      const tmpPasswordConfirmationError = val 
        ? "" 
        : "Please fill in the password field."
      return this.props.onChangeHOC(tmpPasswordConfirmationError, "passwordConfirmationError");
    }
    const tmpBothPasswordsConfirmationError = val === this.props.password
      ? ""
      : "Password and confirm password must be the same."
    this.props.onChangeHOC(tmpBothPasswordsConfirmationError, "passwordConfirmationError"
    );
  });

  render() {
    return (
      <div
        className="login-form-container"
        tabIndex="0"
        onKeyPress={(event) => this.handleKeyPress(event)}
      >
        <h4 className="at-form-input__title">Password</h4>
        <CustomFormInput
          type="password"
          value={this.props.password}
          disabled={!this.props.resetTokenValid} 
          onChangeValue={(val) => this.onChangeNewPassword(val)}
          inputError={this.props.passwordError}
          errorMessagePlain
          placeholder={"Password"}
        />
        <h4 className="at-form-input__title mt-20">Confirmed Password</h4>
        <CustomFormInput
          type="password"
          value={this.props.confirmedPassword}
          disabled={!this.props.resetTokenValid}
          onChangeValue={(val) => this.onChangeConfirmNewPassword(val)}
          inputError={this.props.passwordConfirmationError}
          errorMessagePlain
          placeholder={"Confirmed Password"}
        />
        <div style={{display: "flex", flexDirection: "column", margin: "24px 0", gap: 8}}>
          {passwordChecks(this.props.password)?.map(({ label, condition }, index) => (
            <div key={index}>
              <AtlasIcon
                svgHref={`atlas-${ condition ? 'tick' : 'close' }-circle`}
                style={{width: 20, height: 20, marginRight: 2, fill: condition ? "#12B76A" : "#F04438"}}/> 
              {label}
            </div>
          ))}
        </div>  
        <div style={{ textAlign: "right" }}>
          <button
            disabled={
              !this.props.password ||
              !this.props.confirmedPassword ||
              this.props.passwordError ||
              this.props.passwordConfirmationError ||
              !this.props.resetTokenValid
            }
            className="btn-new btn-new--primary text-uppercase w-100"
            type="submit"
            onClick={() => this.props.onClickSubmitPassword()}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
