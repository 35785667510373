import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";

import { numberWithCommas } from "utils/thousandSeparator";
import { Get } from "utils/axios";
import { requestError } from "utils/requestHandler";

const HOC = (WrappedComponent) => {
  class WithHOC extends Component {
    state = {
      requests: [],
      loading: false,

      currentData: [],
      personalSubsales: [],
      personalSubsalesComm: {},
      submittedSubsales: [],
      submittedSubsalesComm: {},
      initialSubmittedSubsales: [],
      combineSubsales: {},
      initialCombineSubsales: [],
      combineSubsalesComm: {},
      showSubmittedUnitOnly: "2",
      subsalesType: "All",
      subsalesView: "Sale",
    };

    load = (param) => this.setState({ loading: param });
    onChangePersonalSubsalesHOC = (val, context) =>
      this.setState({ [context]: val });

    getPersonalSubsales = (encodedIdentity, startDate, endDate) => {
      Get(
        `/connector/sales/date_summary?type_id=2&identity_id=${encodedIdentity}${startDate ? `&start_date=${startDate}&end_date=${endDate}` : ""}`,
        this.getPersonalSubsalesSuccess,
        this.getPersonalSubsalesError,
        this.load,
      );
    };
    getPersonalSubsalesSuccess = (payload) => {
      let converted = [];
      let combined = [];
      let booked = [];
      this.setState({ subsalesType: "All", showSubmittedUnitOnly: "2" });
      payload?.subsale_without_booked?.length > 0 &&
        payload.subsale_without_booked.map((data) => {
          converted.push({
            ...data,
            form_no: data.form_no || data.confirmation_form_number,
            grossCommission: data.grossCommission || 0,
            agency_comm: data.agency_comm || 0,
          });
        });

      payload?.subsale_with_booked?.length > 0 &&
        payload.subsale_with_booked.map((data) => {
          booked.push({
            ...data,
            booked_unit: true,
            form_no: data.form_no || data.confirmation_form_number,
            grossCommission: data.grossCommission || 0,
            agency_comm: data.agency_comm || 0,
          });
        });

      payload?.combined_sale?.length > 0 &&
        payload.combined_sale.map((data) => {
          combined.push({
            ...data,
            booked_unit: data.comm_status === "Pending",
            form_no: data.form_no || data.confirmation_form_number,
          });
        });

      this.setState({
        currentData: combined,
        personalSubsales: converted,
        submittedSubsales: booked,
        initialSubmittedSubsales: booked,
        combineSubsales: this.processSubsalesSubmittedSales(combined),
        initialCombineSubsales: combined,
      });
    };
    getPersonalSubsalesError = (error) => requestError(error);

    processSubsalesSubmittedSales = (data) => {
      switch (this.state.subsalesType) {
        case "Rental":
          return _.filter(data, { type: "Rental" });
        case "Sale":
          return _.filter(data, { type: "Sale" });
        default:
          return data;
      }
    };

    getTeamPersonalSubsales = (encodedIdentityID, startDate, endDate) => {
      let tempURL = "/connector/sales/date_summary";
      const { role } = window.location.href.includes("/admin-impersonate")
        ? this.props.data.currentSignInProfileReducer
        : this.props.data.profileReducer;

      if (role === "Personal Assistant") {
        tempURL = "/connector/sales/team_date_summary";
      }
      Get(
        `${tempURL}?type_id=2&identity_id=${encodedIdentityID}${startDate ? `&start_date=${startDate}&end_date=${endDate}` : ""}`,
        this.getTeamPersonalSubsalesSuccess,
        this.getTeamPersonalSubsalesError,
        this.load,
      );
    };
    getTeamPersonalSubsalesSuccess = (payload) => {
      let booked = [];
      let combined = [];
      let converted = [];
      payload.subsale_without_booked.length > 0 &&
        payload.subsale_without_booked.map((data) => {
          converted.push({
            ...data,
            form_no: data.form_no || data.confirmation_form_number,
            grossCommission: data.grossCommission || 0,
          });
        });

      payload.combined_sale.length > 0 &&
        payload.combined_sale.map((data) => {
          combined.push({
            ...data,
            booked_unit: data.comm_status === "Pending",
            form_no: data.form_no || data.confirmation_form_number,
            grossCommission: data.grossCommission || 0,
          });
        });

      payload.subsale_with_booked.length > 0 &&
        payload.subsale_with_booked.map((data) => {
          booked.push({
            ...data,
            booked_unit: true,
            form_no: data.form_no || data.confirmation_form_number,
          });
        });

      this.setState({
        currentData: combined,
        personalSubsales: converted,
        submittedSubsales: this.processSubsalesSubmittedSales(booked),
        initialSubmittedSubsales: booked,
        combineSubsales: this.processSubsalesSubmittedSales(combined),
        initialCombineSubsales: combined,
      });
    };
    getTeamPersonalSubsalesError = (error) => requestError(error);

    render = () => {
      return (
        <WrappedComponent
          {...this.props}
          onLoadMySubsales={this.state.loading}
          personalSubsales={this.state.personalSubsales}
          personalSubsalesComm={this.state.personalSubsalesComm}
          subsalesType={this.state.subsalesType}
          subsalesView={this.state.subsalesView}
          submittedSubsales={this.state.submittedSubsales}
          submittedSubsalesComm={this.state.submittedSubsalesComm}
          initialSubmittedSubsales={this.state.initialSubmittedSubsales}
          showSubmittedUnitOnly={this.state.showSubmittedUnitOnly}
          initialCombineSubsales={this.state.initialCombineSubsales}
          combineSubsales={this.state.combineSubsales}
          combineSubsalesComm={this.state.combineSubsalesComm}
          currentData={this.state.currentData}
          onChangePersonalSubsalesHOC={this.onChangePersonalSubsalesHOC}
          getPersonalSubsales={this.getPersonalSubsales}
          getTeamPersonalSubsales={this.getTeamPersonalSubsales}
        />
      );
    };
  }
  const mapStateToProps = (state) => ({ data: state });
  return connect(mapStateToProps)(WithHOC);
};

export default HOC;
