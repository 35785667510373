import React from 'react'
import { MoonLoader } from 'react-spinners'
import { FiEdit } from 'react-icons/fi'
import { BiCarousel } from 'react-icons/bi'
import { FaShareAlt } from 'react-icons/fa'
import { BsInfoCircleFill, BsTrash3Fill } from 'react-icons/bs'

import SVGIcon from "components/Icon";
import AtButton from "components/Button";
import AtlasIcon from "components/Icon/atlasIcon";

const processIconType = ( param, style ) => {
  switch( param ) {
    case 'attach': return <BiCarousel className="svg-icon-info"/>
    case 'info': return <SVGIcon className="svg-icon-info" svgHref={ 'icon-info-circle-fill' } />;
    case 'details': return <BsInfoCircleFill className="svg-icon-info" svgHref={ 'icon-info' } />;
    case 'duplicate': return <AtlasIcon svgHref={ 'atlas-copy' } style={{ width: 18, height: 18, fill: '#3B82F6' }}/>;
    case 'edit': return <FiEdit style={{ width: 18, height: 18, color: '#007bff' }}/>;
    case 'download': return <AtlasIcon className="svg-icon-info" svgHref={ 'atlas-receive-square' } />;
    case 'release': return <SVGIcon className="svg-icon-info" svgHref={ 'icon-unlock' } />;
    case 'delete': return <BsTrash3Fill style={{ width: 18, height: 18, color: '#F04438' }}/>;
    case 'statement of approval':
    case 'view-doc': return  <AtlasIcon className="svg-icon-info" svgHref={ 'atlas-document-text' } />;
    case 'document' : return <AtlasIcon className="svg-icon-info" svgHref={ 'atlas-document-download' } />;
    case 'share' : return <FaShareAlt style={{ width: 16, height: 16, color: '#007bff' }}/>;
    case 'preview': return <AtlasIcon className="svg-icon-info" svgHref={ 'atlas-scan-barcode' } />;
    case 'check': return <MoonLoader sizeunit= {"px" } size={ 30 } color={ '#16232c' } loading={ true } />
    default: return <BsInfoCircleFill style={{ width: 20, height: 20, color: '#3B82F6', ...style }} />;
  }
};

const TableContent = ({
  index,
  columns,
  actionLabel,
  maxActionLength,
  headerRearrangeData,
  actionColumStyle,
  actionColumnContent = [],
  rowData,
}) => {
  const renderHighlightColumn = (columnData) => {
    if (
      headerRearrangeData?.columnValue !== undefined &&
      headerRearrangeData.columnValue === (columnData.accessor || columnData.searchFlag)
    ) {
      return "grid-top-object";
    }
    return "";
  };

  const getTooltipContent = (tooltipContent) => {
    return typeof tooltipContent !== "function"
      ? tooltipContent
      : tooltipContent(rowData);
  };

  return (
    <div
      className={`at-table-row-cont at-table-row-cont-${index % 2 ? "odd" : "even"}`}
      key={`row-${index}`}>
      <div className="at-table__height-control">
        <ul className="at-table-row grid-controller">
          {columns.map((columnData, columnIndex) => {
            return (
              <li
                key={`${columnData.header}-${columnIndex}`}
                style={{ width: "100%", ...columnData.columnStyle }}
                className={`at-table-row__item at-table__${columnIndex} 
                  ${renderHighlightColumn(columnData)}
                  ${
                    columnData.columnClass &&
                    typeof columnData.columnClass === "function"
                      ? columnData.columnClass(rowData)
                      : columnData.columnClass || ""
                  }`}>
                <span className="at-table-row__item-label">
                  {columnData.header}
                </span>
                {columnData &&
                  !columnData.renderColumn &&
                  columnData.accessor && (
                    <p className={`${columnData.contentClass || ""}`}>
                      {columnData.accessor && (
                        <span>
                          {rowData[columnData.accessor] === undefined ||
                          rowData[columnData.accessor] === null ||
                          rowData[columnData.accessor] === ""
                            ? "N/A"
                            : rowData[columnData.accessor]}
                        </span>
                      )}
                    </p>
                  )}
                {columnData && columnData.renderColumn && (
                  <div
                    className={`w-100 at-custom-column ${columnData.customClass || ""}`}
                    style={{ marginTop: 5, ...columnData.renderColumnSytle }}>
                    {columnData.renderColumn && columnData.renderColumn(rowData, index)}
                  </div>
                )}
              </li>
            );
          })}
          {actionColumnContent.length > 0 && (
            <li
              className={`at-table-row__item at-table__actions`}
              style={{
                minWidth: (maxActionLength * 60) < 120
                  ? 120 
                  : (maxActionLength * 60),
                width: "100%",
                maxWidth:(maxActionLength * 60) < 120
                  ? 120 
                  : (maxActionLength * 70),
                  ...actionColumStyle,
              }}>
              <span className="at-table-row__item-label">{actionLabel || "Actions"}</span>
              {actionColumnContent.map((action, index) => {
                if (
                  !action.onShow ||
                  (action.onShow && action.onShow(rowData))
                ) {
                  return (
                    <AtButton
                      key={`${action.name}-${index}`}
                      disabled={action.disabled && action.disabled(rowData)}
                      containerStyle={{ marginRight: 2 }}
                      className={`at-table-icon-button ${action.iconClass || ""}`}
                      tooltip={true}
                      tooltipChildren={
                        action.disabled &&
                        action.disabled(rowData) &&
                        action.disabledTooltipContent ? (
                          <span className="text-capitalize">
                            {action.disabledTooltipContent}
                          </span>
                        ) : (
                          <span className="text-capitalize">
                            {action.tooltipContent || action.name}
                          </span>
                        )
                      }
                      children={
                        <>
                          {action.renderChildren &&
                          typeof action.renderChildren === "function"
                            ? action.renderChildren(rowData)
                            : processIconType(action.name, action.iconStyle)}
                        </>
                      }
                      tooltipPosition={"top"}
                      tooltipID={`details-${action.name}-${rowData.id}`}
                      onClick={() => action.onClick(rowData)}
                    />
                  );
                }
              })}
            </li>
          )}
        </ul>
      </div>
      {actionColumnContent && actionColumnContent.length > 0 && (
        <div className="at-table-row__mobile-action">
          {actionColumnContent.map((action, actionIndex) => {
            if (!action.onShow || (action.onShow && action.onShow(rowData))) {
              return (
                <AtButton
                  key={actionIndex}
                  disabled={action.disabled && action.disabled(rowData)}
                  className={`${action.iconClass || ""}`}
                  style={{ color: action.color || "#007bff" }}
                  tooltipChildren={
                    action.disabled &&
                    action.disabled(rowData) &&
                    action.disabledTooltipContent ? (
                      <span className="text-capitalize">
                        {action.disabledTooltipContent}
                      </span>
                    ) : (
                      <span className="text-capitalize">
                        {action.tooltipContent || action.name}
                      </span>
                    )
                  }
                  children={
                    <>
                      {action.renderChildren &&
                      typeof action.renderChildren === "function"
                        ? action.renderChildren(rowData)
                        : processIconType(action.name, action.iconStyle)}
                      <span className="text-capitalize ml-2">
                        {getTooltipContent(action.tooltipContent) ||
                          action.name}
                      </span>
                    </>
                  }
                  onClick={() => action.onClick(rowData, index)}
                />
              );
            }
          })}
        </div>
      )}
    </div>
  );
};

export default TableContent;
